<template>
    <div>
        <base-header class="pb-6">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0"></h6>
                </div>      
            </div>
        </base-header>
        <div class="container-fluid mt--6">
            <div class="card mb-4">
                <div class="card-header mt--4">
                    <div class="row">
                        <div class="col">
                            <h3 class="mb-0 text-center">{{ tt('mcr_detail') }}</h3>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-6">
                            <h3>{{ tt('mcr_information') }}</h3>
                        </div>
                        <div v-if="formtype == 'edit'" class="col-6 text-right">
                            <base-button size="sm" type="default" @click="editMcrInformation">{{ tt('edit') }}</base-button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <table class="table table-responsive-md table-bordered">
                                <tbody>
                                    <tr>
                                        <th class="bg-secondary">{{ tt('subject') }}</th>
                                        <td colspan="3">{{ mcrInformation.subject }}</td>
                                    </tr>
                                    <tr>
                                        <th class="bg-secondary">{{ tt('type') }}</th>
                                        <td>{{ mcrInformation.form_description }}</td>
                                        <th class="bg-secondary">{{ tt('created_by') }}</th>
                                        <td>{{ mcrInformation.created_by }}</td>
                                    </tr>
                                    <tr>
                                        <th class="bg-secondary">{{ tt('status') }}</th>
                                        <td>{{ mcrInformation.status }}</td>
                                        <th class="bg-secondary">{{ tt('created_at') }}</th>
                                        <td>{{ mcrInformation.created_at }}</td>
                                    </tr>
                                </tbody>
                            </table> 
                        </div>
                    </div>   
                    <br>
                    <div class="row">
                        <div class="col">
                            <h3>{{ tt('mcr_item') }}</h3>
                        </div>
                        <div v-if="formtype == 'view'" class="col text-right">
                            <base-button size="sm" type="default" @click="tracking()">{{ tt('tracking') }}</base-button>
                        </div>
                        <div v-if="formtype == 'edit'" class="col text-right">
                            <base-button size="sm" type="default" @click="create('a2')">{{ tt('add_new') }}</base-button>
                            <!-- <base-button size="sm" type="default" @click="create(mcrInformation.type)">{{ tt('add_new') }}</base-button> -->
                        </div>
                    </div>
                    <el-table class="table-responsive table-flush" header-row-class-name="thead-light" :data="mcrItem">
                        <el-table-column :label="tt('no')" :prop="tt('no')" min-width="100px" sortable>
                            <template v-slot="{row}">
                                {{ row.id }}
                            </template>
                        </el-table-column>
                        <el-table-column v-if="mcrType != 'b6'" :label="tt('material_number')" :prop="tt('material_number')" min-width="200px" sortable>
                            <template v-slot="{row}">
                                {{ row.material_number }}
                            </template>
                        </el-table-column>
                        <el-table-column v-if="mcrType == 'a1' || mcrType == 'a2' || mcrType == 'b1' || mcrType == 'b2' || mcrType == 'b3'" :label="tt('specification')" :prop="tt('specification')" min-width="200px" sortable>
                            <template v-slot="{row}">
                                {{ row.specification }}
                            </template>
                        </el-table-column>
                        <el-table-column v-if="mcrType == 'a3' || mcrType == 'b4'" :label="tt('note')" :prop="tt('note')" min-width="200px" sortable>
                            <template v-slot="{row}">
                                {{ row.note }}
                            </template>
                        </el-table-column>
                        <el-table-column v-if="mcrType == 'a4'" :label="tt('reason')" :prop="tt('reason')" min-width="200px" sortable>
                            <template v-slot="{row}">
                                {{ row.reason }}
                            </template>
                        </el-table-column>
                        <el-table-column v-if="mcrType == 'b6'" :label="tt('consolidate_from')" :prop="tt('consolidate_from')" min-width="200px" sortable>
                            <template v-slot="{row}">
                                {{ row.prc_consolidate_from }}
                            </template>
                        </el-table-column>
                        <el-table-column v-if="mcrType == 'b6'" :label="tt('consolidate_to')" :prop="tt('consolidate_to')" min-width="200px" sortable>
                            <template v-slot="{row}">
                                {{ row.prc_consolidate_to }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="action" width="100">
                            <template v-slot="{row}">
                                <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true" v-if="authUserPermission['smartcat-attachment-update-folder'] || authUserPermission['smartcat-attachment-delete-folder'] || authUserPermission['smartcat-attachment-moveto-folder'] || authUserPermission['smartcat-attachment-update-file'] || authUserPermission['smartcat-attachment-delete-file'] || authUserPermission['smartcat-attachment-moveto-file']">
                                    <span class="btn btn-sm btn-icon-only text-light">
                                        <i class="fas fa-ellipsis-v mt-2"></i>
                                    </span>
                                    <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                        <el-dropdown-item  v-if="formtype == 'view'" :command="{action:'detail', data:{btn:'detail', mcr_item_code:row.mcr_item_code,mcr_type:mcrInformation.type}}">{{ tt('detail') }}</el-dropdown-item>
                                        <el-dropdown-item v-if="formtype == 'edit'" :command="{action:'detail', data:{btn:'edit', mcr_item_code:row.mcr_item_code,mcr_type:'a2'}}">{{ tt('edit') }}</el-dropdown-item>
                                        <el-dropdown-item v-if="formtype == 'edit'" :command="{action:'remove', data:{id:row.id}}">{{ tt('delete') }}</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="card-footer pb-0 ">                           
                    <span class="float-right mt--4 mb-4">
                      Total Items {{total_mcr_item}}
                    </span>
                </div>
            </div>
            <div class="row">
                <div v-if="formtype == 'edit'" class="col text-center">
                    <router-link :to="'/material/draft-list'" class="btn btn-sm btn-dark">Draft List</router-link>  
                    <base-button size="sm" type="button" class="btn btn-lg btn-login" style="background: linear-gradient(to left,  #2e41db, #5626c7);padding-right: 40px;padding-left: 40px;color: #fff;outline-color: black;" v-on:click="sendMcr(mcr_code)" :disabled="btnSend.onLoading">
                      <span v-if="btnSend.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                      <span v-else>{{ tt('send') }}</span>
                    </base-button>
                </div>
            </div>
        </div>

        <!-- MODAL TRACKING  -->
        <tracking v-if="formTracking.show" form-show="formTracking.show" :mcr-code="mcr_code"/>
        
        <!--Form Edit Subject-->
        <validation-observer v-slot="{invalid}">
            <modal :show.sync="formMcrInformation.show">
                <template slot="header">
                    <h5 class="modal-title">{{tt('edit_subject')}}</h5>
                </template>
                <div>
                    <label class="form-control-label">{{ tt('subject') }} <span class="text-danger">*</span></label>
                    <base-input :name="tt('subject')" :placeholder="tt('subject')" v-model="mcrInformationEdit.new_subject" rules="required"></base-input>
                </div>
                <template slot="footer">
                    <base-button type="secondary" @click="formMcrInformation.show = false">{{ tt('close') }}</base-button>
                    <base-button type="primary" v-on:click="updateMcrInformation" :disabled="btnUpdate.onLoading || invalid">
                        <span v-if="btnUpdate.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                        <span v-else>
                            {{ tt('update') }}
                        </span>
                    </base-button>
                </template>
            </modal>
        </validation-observer>
        <!--Form Edit Subject-->
        
        <modal :show.sync="form.a2.show" size="lg">
            <template slot="header">
                <h5 class="modal-title">{{tt('add_new')}}</h5>
            </template>

            <div class="card">
                <div class="card-header bg-primary">
                    <h4 class="text-white">{{ tt('change_material') }}</h4>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <label class="form-control-label">{{ tt('material_number') }} <span class="text-danger">*</span></label>
                            <base-input :name="tt('material_number')" rules="required">
                                <el-autocomplete
                                    v-model="inputDraftListA2.material_number"
                                    :fetch-suggestions="querySearchMatNum"
                                    :placeholder="tt('choose_material_number')"
                                    :trigger-on-focus="false"
                                    @select="changeMaterialNumberA2"
                                    size="large"
                                    style="width: 100%"
                                    :disabled="form.a2.disabled"
                                ></el-autocomplete>
                            </base-input>
                        </div>
                        <div class="col-md-6">
                            <label class="form-control-label">{{ tt('client_level') }}/{{ tt('plant_level') }} <span class="text-danger">*</span></label>
                            <base-input :name="tt('client_level')" rules="required">
                                <el-select :disabled="form.a2.disabled" class="select-danger" v-model="inputDraftListA2.client_plant_level" @change="changeClientPlantLevelA2" :placeholder="tt('choose_client_level_or_plant_level')">
                                    <el-option class="select-danger" value="client" label="Client" key="client"></el-option>
                                    <el-option class="select-danger" value="plant" label="Plant" key="plant"></el-option>
                                </el-select>
                            </base-input>
                        </div>
                    </div>
                    <div v-if="inputDraftListA2.client_plant_level == 'client'">
                        <div id="accordionClA2">
                            <div class="card z-depth-0 bordered">
                                <div class="card-header bg-secondary border" id="headingOneClA2">
                                    <h4 class="pt-2 pl-2 pb-2 pr-2 mb--1">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <span @click="toggleAccordion(accordion.a2.cl.show, 'clA2')">{{tt('client_level')}}</span>
                                            </div>
                                        </div>
                                    </h4>
                                </div>
                                <div id="collapseClA2" :class="'collapse '+accordion.a2.cl.show">
                                    <div class="card-body border">
                                        <div class="table-responsive">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <label class="form-control-label">{{ tt('item_type') }}</label>
                                                    <base-input :name="tt('item_type')" rules="required">
                                                        <el-select :disabled="form.a2.disabled" class="select-danger" v-model="inputDraftListA2.item_type_code" :placeholder="tt('choose_item_type')">
                                                            <el-option class="select-danger" :value="it" :label="it" :key="it" v-for="it in  itemType"></el-option>
                                                        </el-select>
                                                    </base-input>
                                                </div>
                                                <div class="col-md-6">
                                                    <label class="form-control-label">{{ tt('critical_non_critical') }}</label>
                                                    <base-input :name="tt('critical_non_critical')" rules="required">
                                                        <el-select :disabled="form.a2.disabled" class="select-danger" v-model="inputDraftListA2.critical_non_critical" :placeholder="tt('choose_critical_non_critical')">
                                                            <el-option class="select-danger" value="Critical" label="Critical" key="Critical"></el-option>
                                                            <el-option class="select-danger" value="Non Critical" label="Non Critical" key="Non Critical"></el-option>
                                                        </el-select>
                                                    </base-input>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <label class="form-control-label">{{ tt('fabric_or_non_fabrication') }}</label>
                                                    <base-input :name="tt('fabric_or_non_fabrication')" rules="required">
                                                        <el-select :disabled="form.a2.disabled" class="select-danger" v-model="inputDraftListA2.fab_non_fab" :placeholder="tt('choose_fabric_or_non_fabrication')">
                                                            <el-option class="select-danger" :value="fnf" :label="fnf" :key="fnf" v-for="fnf in  fabNonFab"></el-option>
                                                        </el-select>
                                                    </base-input>
                                                </div>
                                                <div class="col-md-6">
                                                    <label class="form-control-label">{{ tt('bom_or_non_bom') }}</label>
                                                    <base-input :name="tt('bom_or_non_bom')" rules="required">
                                                        <el-select :disabled="form.a2.disabled" class="select-danger" v-model="inputDraftListA2.bom_non_bom" :placeholder="tt('choose_bom_or_non_bom')">
                                                            <el-option class="select-danger" :value="bnb" :label="bnb" :key="bnb" v-for="bnb in  bomNonBom"></el-option>
                                                        </el-select>
                                                    </base-input>
                                                </div>
                                            </div>
                                            <label class="form-control-label">{{ tt('item_name') }}</label>
                                            <base-input :disabled="form.a2.disabled" :name="tt('item_name')" :placeholder="tt('item_name')" v-model="inputDraftListA2.item_name" rules="required"></base-input>

                                            <label class="form-control-label mt-4">{{ tt('specification') }}</label>
                                            <base-input :name="tt('specification')" rules="required">
                                                <textarea :disabled="form.a2.disabled" class="form-control" rows="5" v-model="inputDraftListA2.specification"></textarea>
                                            </base-input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="accordionMpnA2">
                            <div class="card z-depth-0 bordered">
                                <div class="card-header bg-secondary border" id="headingOneMpnA2">
                                    <h4 class="pt-2 pl-2 pb-2 pr-2 mb--1">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <span @click="toggleAccordion(accordion.a2.mpn.show, 'mpnA2')">{{tt('manufacturer_part_number')}}</span>
                                            </div>
                                            <div class="col-md-6 text-right">
                                                <base-button v-if="!form.a2.disabled && form.a2.type == 'edit'" size="sm" type="default" @click="addMpnA2">{{ tt('add_new') }}</base-button>
                                            </div>
                                        </div>
                                    </h4>
                                </div>
                                <div id="collapseMpnA2" :class="'collapse '+accordion.a2.mpn.show">
                                    <div class="card-body border">
                                        <div class="table-responsive">
                                            <table class="table align-items-center table-flush table-bordered">
                                                <thead class="card-header bg-primary">
                                                    <tr>
                                                        <th min-width="500" class="text-white">{{tt('manufacturer_code')}}</th>
                                                        <th class="text-white">{{tt('mpn')}}</th>
                                                        <th class="text-white">{{tt('type')}}</th>
                                                        <th class="text-white">{{tt('note')}}</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(mpn, key) in manufacturePartNumberA2.manufacture_code" v-bind:key="mpn.manufacture_code">
                                                        <td>
                                                            <el-autocomplete
                                                                :disabled="form.a2.disabled"
                                                                v-model="manufacturePartNumberA2.manufacture_code[key]"
                                                                :fetch-suggestions="querySearchManufacture"
                                                                :placeholder="tt('choose_manufacture')"
                                                                :trigger-on-focus="false"
                                                                @focus="selectedManufactureKey(key)"
                                                                @select="selectManufacture"
                                                                size="small"
                                                                style="width: 100%"
                                                            ></el-autocomplete>
                                                        </td>
                                                        <td><input :disabled="form.a2.disabled" class="form-control form-control-sm" :name="tt('mpn')" :placeholder="tt('mpn')" v-model="manufacturePartNumberA2.mpn[key]" rules="required"></td>
                                                        <td>
                                                            <select :disabled="form.a2.disabled" class="form-control-sm" v-model="manufacturePartNumberA2.manufacture_type[key]">
                                                                    <option value="">Choose Type</option>
                                                                <option :value="mt.code" v-for="mt in manrefType" v-bind:key="mt.id">{{mt.description}}</option>
                                                            </select>
                                                        </td>
                                                        <td><input :disabled="form.a2.disabled" class="form-control form-control-sm" :name="tt('note')" :placeholder="tt('note')" v-model="manufacturePartNumberA2.manufacture_note[key]" rules="required"></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="accordionEqiA2">
                            <div class="card z-depth-0 bordered">
                                <div class="card-header bg-secondary border" id="headingOneEqiA2">
                                    <h4 class="pt-2 pl-2 pb-2 pr-2 mb--1">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <span @click="toggleAccordion(accordion.a2.eqi.show, 'eqiA2')">{{tt('equipment_&_quantity_install')}}</span>
                                            </div>
                                            <div class="col-md-6 text-right">
                                                <base-button v-if="!form.a2.disabled && form.a2.type == 'edit'" size="sm" type="default" @click="addEqiA2">{{ tt('add_new') }}</base-button>
                                            </div>
                                        </div>
                                    </h4>
                                </div>
                                <div id="collapseEqiA2" :class="'collapse '+accordion.a2.eqi.show">
                                    <div class="card-body border">
                                        <div class="table-responsive">
                                            <table class="table align-items-center table-flush table-bordered">
                                                <thead class="card-header bg-primary">
                                                    <tr>
                                                        <th class="text-white">{{tt('plant')}}</th>
                                                        <th min-width="500" class="text-white">{{tt('equipment_code')}}</th>
                                                        <th class="text-white">{{tt('quantity_install')}}</th>
                                                        <th class="text-white">{{tt('drawing_number')}}</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(eqi, key) in equipmentQuantityInstallA2.equipment_code" v-bind:key="eqi.equipment_code">
                                                        <td>
                                                            <el-autocomplete
                                                                :disabled="form.a2.disabled"
                                                                v-model="equipmentQuantityInstallA2.plant[key]"
                                                                :fetch-suggestions="querySearchPlant"
                                                                :placeholder="tt('choose_plant')"
                                                                :trigger-on-focus="false"
                                                                @focus="selectedPlantKey(key)"
                                                                @select="selectPlant"
                                                                size="small"
                                                                style="width: 100%"
                                                            ></el-autocomplete>
                                                        </td>
                                                        <td>
                                                            <el-autocomplete
                                                                :disabled="form.a2.disabled"
                                                                v-model="equipmentQuantityInstallA2.equipment_code[key]"
                                                                :fetch-suggestions="querySearchEquipment"
                                                                :placeholder="tt('choose_equipment')"
                                                                :trigger-on-focus="false"
                                                                @focus="selectedEquipmentKey(key)"
                                                                @select="selectEquipment"
                                                                size="small"
                                                                style="width: 100%"
                                                            ></el-autocomplete>
                                                        </td>
                                                        <td><input :disabled="form.a2.disabled" class="form-control form-control-sm" :name="tt('qty_install')" :placeholder="tt('qty_install')" v-model="equipmentQuantityInstallA2.qty_installed[key]" rules="required"></td>
                                                        <td>
                                                            <select :disabled="form.a2.disabled" class="form-control-sm" v-model="equipmentQuantityInstallA2.drawing_number[key]">
                                                                <option value="">Choose Type</option>
                                                                <option :value="dm.drawing_number" v-for="dm in drawingMaster" v-bind:key="dm.drawing_number">{{dm.drawing_name}}</option>
                                                            </select>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="inputDraftListA2.client_plant_level == 'plant'">
                        <div id="accordionPlA2">
                            <div class="card z-depth-0 bordered">
                                <div class="card-header bg-secondary border" id="headingOnePlA2">
                                    <h4 class="pt-2 pl-2 pb-2 pr-2 mb--1">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <span @click="toggleAccordion(accordion.a2.pl.show, 'plA2')">{{tt('plant_level')}} <span class="text-danger">*</span></span>
                                            </div>
                                        </div>
                                    </h4>
                                </div>
                                <div id="collapsePlA2" :class="'collapse '+accordion.a2.pl.show">
                                    <div class="card-body border">
                                        <div class="table-responsive">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <label class="form-control-label mt-5">{{ tt('plant') }} <span class="text-danger">*</span></label>
                                                    <base-input>
                                                        <el-select :disabled="form.a2.disabled" class="select-danger" v-model="inputDraftListA2.plant_code" :placeholder="tt('choose_plant')"  @change="getMaterialPlant">
                                                            <el-option class="select-danger" :value="pc.plant_code" :label="pc.plant_code +' - '+ pc.description" v-bind:key="pc.plant_code" v-for="pc in  plantLevel"></el-option>
                                                        </el-select>
                                                    </base-input>
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-md-6">
                                                    <label class="form-control-label">{{ tt('abc_indicator') }}</label>
                                                    <base-input :name="tt('abc_indicator')" rules="required">
                                                        <el-select :disabled="form.a2.disabled" class="select-danger" v-model="inputDraftListA2.abc_indicator_code" :placeholder="tt('choose_abc_indicator')">
                                                            <el-option class="select-danger" :value="ai.abc_indicator_code" :label="ai.abc_indicator_code+' - '+ai.abc_indicator_desc" v-bind:key="ai.abc_indicator_code" v-for="ai in  abcIndicator"></el-option>
                                                        </el-select>
                                                    </base-input>
                                                </div>
                                                <div class="col-md-6">
                                                    <label class="form-control-label">{{ tt('minimum_stock') }} <span class="text-danger">*</span></label>
                                                    <base-input :disabled="form.a2.disabled" :name="tt('minimum_stock')" :placeholder="tt('minimum_stock')" v-model="inputDraftListA2.min_stock" rules="required"></base-input>
                                                </div>
                                            </div>

                                            <div class="row mb--3">
                                                <div class="col-md-6">
                                                    <label class="form-control-label">{{ tt('mrp_type') }}</label>
                                                    <base-input :name="tt('mrp_types')" rules="required">
                                                        <el-select :disabled="form.a2.disabled" class="select-danger" v-model="inputDraftListA2.mrp_type_code" :placeholder="tt('choose_mrp_type')">
                                                            <el-option class="select-danger" :value="mt.mrp_type_code" :label="mt.mrp_type_code+' - '+mt.mrp_type_desc" v-bind:key="mt.mrp_type_code" v-for="mt in mrpTypes"></el-option>
                                                        </el-select>
                                                    </base-input>
                                                </div>
                                                <div class="col-md-6 ">
                                                    <label class="form-control-label">{{ tt('maximum_stock') }} <span class="text-danger">*</span></label>
                                                    <base-input :disabled="form.a2.disabled" :name="tt('maximum_stock')" :placeholder="tt('maximum_stock')" v-model="inputDraftListA2.max_stock" rules="required"></base-input>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <label class="form-control-label mt-5">{{ tt('reason') }} <span class="text-danger">*</span></label>
                    <base-input :disabled="form.a2.disabled" :name="tt('reason')" :placeholder="tt('reason')" v-model="inputDraftListA2.reason" rules="required"></base-input>

                    <label class="form-control-label">{{ tt('note') }}</label>
                    <base-input :name="tt('note')" rules="required">
                        <textarea :disabled="form.a2.disabled" class="form-control" rows="5" v-model="inputDraftListA2.note"></textarea>
                    </base-input>
                </div>
            </div>
            <template slot="footer">
                <base-button type="secondary" @click="form.a2.show = false">{{ tt('close') }}</base-button>
                <base-button v-if="form.a2.type == 'add'" type="primary" @click="saveA2('add')">{{ tt('add') }}</base-button>
                <base-button v-else-if="form.a2.type == 'edit'" type="primary" @click="saveA2('edit')">{{ tt('edit') }}</base-button>
            </template>
        </modal>
        
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import draftList from '@/services/material/draftList.service';
    import materialType from '@/services/master/materialType.service';
    import plantCode from '@/services/master/plantCode.service';
    import unitOfMeasure from '@/services/dictionary/unitOfMeasure.service';
    import manufacture from '@/services/master/manufacture.service';
    import manrefType from '@/services/master/manrefType.service';
    import equipmentCode from '@/services/master/equipmentCode.service';
    import abcIndicator from '@/services/master/abcIndicator.service';
    import mrpTypes from '@/services/master/mrpTypes.service';
    import mrpController from '@/services/master/mrpController.service';
    import valuationClass from '@/services/master/valuationClass.service';
    import valuationCategory from '@/services/master/valuationCategory.service';
    import valuationType from '@/services/master/valuationType.service';
    import priceControl from '@/services/master/priceControl.service';
    import salesOrganization from '@/services/master/salesOrganization.service';
    import distributionChannel from '@/services/master/distributionChannel.service';
    import division from '@/services/master/division.service';
    import taxClassification from '@/services/master/taxClassification.service';
    import itemCategoryGroup from '@/services/master/itemCategoryGroup.service';
    import accountAssignmentGroup from '@/services/master/accountAssignmentGroup.service';
    import transportationGroup from '@/services/master/transportationGroup.service';
    import loadingGroup from '@/services/master/loadingGroup.service';
    import profitCenter from '@/services/master/profitCenter.service';
    import warehouse from '@/services/master/warehouse.service';
    import storageType from '@/services/master/storageType.service';
    import materialNumber from '@/services/master/materialNumber.service';
    import locationCode from '@/services/master/locationCode.service';
    import catalogType from '@/services/master/catalogType.service';
    import materialGroup from '@/services/master/materialGroup.service';
    import endUserSearchMaterial from '@/services/endUserSearch/endUserSearchMaterial.service';
    import drawingMaster from '@/services/dictionary/drawingMaster.service';
    import standardItemName from '@/services/dictionary/standardItemName.service';
    import characteristicItemName from '@/services/dictionary/characteristicItemName.service';
    import lotSize from '@/services/master/lotSize.service';
    import procurementType from '@/services/master/procurementType.service';
    import specialProcurementType from '@/services/master/specialProcurementType.service';
    import scopeOfSupply from '@/services/dictionary/scopeofSupply.service';
    import natoGroupClass from '@/services/dictionary/natoGroupClass.service';

    export default {        
        data() {
            return {  
                formTracking: {
                    show: false
                },
                loadTimeout: null,  
                btnUpdate: {
                    onLoading: false
                }, 
                btnSend: {
                    onLoading: false
                },       
                formMcrInformation: {
                    show: false
                },                
                form: {
                    a2: {
                        show: false,
                        disabled: false,
                        type: 'add',
                    },
                    
                },
                formDetail: {
                    show: false
                },
                inputDraftListA2: {
                    material_number: '',
                    client_plant_level: '',
                    item_type_code: '',
                    critical_non_critical: '',
                    bom_non_bom: '',
                    fab_non_fab: '',
                    item_name: '',
                    specification: '',
                    plant_code: '',
                    abc_indicator_code: '',
                    min_stock: '',
                    mrp_type_code: '',
                    max_stock: '',
                    reason: '',
                    note: ''
                },
                mcrType: 'a2',  
                
                draftList: {},
                mcrInformation: {},
                mcrInformationEdit: {
                    new_subject: ''
                },
                valuesmat:[],
                parameter: {},
                category: {},
                mcrItem: [],
                materialType: {},
                plantCode: [],
                unitOfMeasure: {},
                manufacture: [],
                manrefType: {},
                equipmentCode: [],
                abcIndicator: {},
                mrpTypes: {},
                mrpController: {},
                valuationClass: {},
                valuationCategory: {},
                valuationType: {},
                priceControl: {},
                salesOrganization: {},
                distributionChannel: {},
                division: {},
                taxClassification: {},
                itemCategoryGroup: {},
                accountAssignmentGroup: {},
                transportationGroup: {},
                loadingGroup: {},
                profitCenter: {},
                warehouse: {},
                storageType: {},
                materialNumber: [],
                locationCode: {},
                materialGroup: {},
                catalogType: {},
                itemType: {},
                fabNonFab: {},
                bomNonBom: {},
                drawingMaster: {},
                standardItemName: [],
                characteristicItemName: [],
                characteristicValue: [],
                lotSize: [],
                procurementType: [],
                specialProcurementType: [],
                scopeOfSupply: [],
                natoGroupClass: [],
                manufactureKey: '',
                equipmentKey: '',
                plantKey: '',
                // manufacturePartNumber: {
                //     manufacture_code: [],
                //     mpn: [],
                //     manufacture_type: [],
                //     manufacture_note: []
                // },
                manufacturePartNumberA2: {
                    manufacture_code: [],
                    mpn: [],
                    manufacture_type: [],
                    manufacture_note: []
                },
                
                // equipmentQuantityInstall: {
                //     equipment_code: [],
                //     qty_installed: [],
                //     drawing_number: [],
                // },
                equipmentQuantityInstallA2: {
                    plant: [],
                    equipment_code: [],
                    qty_installed: [],
                    drawing_number: [],
                },
                
                attachmentDocument: {
                    file_name: [],
                    file_type: [],
                },
                plantLevel: [],
                tempMrp: {
                    abc_indicator: '',
                    mrp_type: '',
                    mrp_controller: '',
                    lot_size: '',
                    min: '',
                    max: '',
                    procurement_type: '',
                    special_proc: '',
                },
                mrp: {
                    abc_indicator: [],
                    mrp_type: [],
                    mrp_controller: [],
                    lot_size: [],
                    min: [],
                    max: [],
                    procurement_type: [],
                    special_proc: [],
                },

                accordion: {
                    a2: {
                        cl: {
                            show: 'show'
                        },
                        pl: {
                            show: 'show'
                        },
                        mpn: {
                            show: 'show'
                        },
                        eqi: {
                            show: 'show'
                        },
                    },
                    
                },
                mcr_code: window.location.hash.split('/')[3],
                token: window.location.hash.split('/')[4],
                formtype:window.location.hash.split('/')[5],
                total_mcr_item: '',
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.get()
            this.getMaterialNumber()
            this.getPlantCode()
            this.getManufacture()
            this.getManrefType()
            this.getEquipmentCode()
            this.getAbcIndicator()
            this.getMrpTypes()
            this.getDrawingMaster();
        },
        methods: {
            get() {
                let context = this;               
                Api(context, draftList.getMcrItem(context.mcr_code, context.token)).onSuccess(function(response) {                 
                    context.mcrItem = response.data.data.mcr_item;
                    context.mcrInformation = response.data.data.mcr[0];
                    context.parameter = response.data.data.parameter;
                    context.category = response.data.data.category;
                    context.itemType = response.data.data.item_type;
                    context.fabNonFab = response.data.data.fab_non_fab;
                    context.bomNonBom = response.data.data.bom_non_bom;
                    context.mcrInformationEdit.new_subject = response.data.data.mcr[0].subject;   
                    context.total_mcr_item = response.data.data.mcr_item.length;                 
                })
                .call()
            },
            getPlantCode() {
                let context = this;               
                Api(context, plantCode.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    response.data.data.data.data.forEach(function(item, index) {
                        context.plantCode[index] = {
                          'code': item['plant_code'],
                          'value': item['plant_code'] +' - '+ item['description']
                        };
                    });
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.plantCode = [];
                    }
                })
                .call()
            },
            getManufacture() {
                let context = this;               
                Api(context, manufacture.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    response.data.data.data.data.forEach(function(item, index) {
                        context.manufacture[index] = {
                            'code': item['manufacture_code'],
                            'value': item['manufacture_code'] +' - '+ item['manufacture_name']
                        };
                    });
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.manufacture = [];
                    }
                })
                .call()
            },
            getManrefType() {
                let context = this;               
                Api(context, manrefType.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    context.manrefType = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.manrefType = [];
                    }
                })
                .call()
            },
            getEquipmentCode() {
                let context = this;               
                Api(context, equipmentCode.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    response.data.data.data.data.forEach(function(item, index) {
                        context.equipmentCode[index] = {
                            'code': item['equipment_code'],
                            'value': item['equipment_code'] +' - '+ item['equipment_name']
                        };
                    });
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.equipmentCode = [];
                    }
                })
                .call()
            },
            getAbcIndicator() {
                let context = this;               
                Api(context, abcIndicator.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    context.abcIndicator = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.abcIndicator = [];
                    }
                })
                .call()
            },
            getMrpTypes() {
                let context = this;               
                Api(context, mrpTypes.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    context.mrpTypes = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.mrpTypes = [];
                    }
                })
                .call()
            },
            
            getMaterialNumber() {
                let context = this;               
                Api(context, materialNumber.get(null, {per_page: 'none'})).onSuccess(function(response) {
                    response.data.data.forEach(function(item, index) {
                        context.materialNumber[index] = {
                            'code': item['material_number'],
                            'value': item['material_number']
                        };
                    });
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.materialNumber = [];
                    }
                })
                .call()
            },
            getDrawingMaster() {
                let context = this;               
                Api(context, drawingMaster.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.drawingMaster = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.drawingMaster = [];
                    }
                })
                .call()
            },
            handleTableAction(command) {
                this.formTracking.show = false;
                switch (command.action) {
                    case 'detail':
                            this.detail(command.data);
                        break;
                    case 'remove':
                            this.remove(command.data);
                        break;
                    default:
                        break;
                }
            },
            detail(data) {
                let context = this;
                data.mcr_type = 'a2';
                

                if (data.mcr_type == 'a2') {
                    if (data.btn == 'detail') {
                        context.form.a2.show = true;
                        context.form.a2.disabled = true;
                        context.form.a2.type = 'detail';
                    } else {
                        context.form.a2.show = true;
                        context.form.a2.disabled = false;
                        context.form.a2.type = 'edit';
                    }

                    Api(context, draftList.getDetailMcrItem(data.mcr_item_code)).onSuccess(function(response) {    
                        context.inputDraftListA2 = response.data.data[0];
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.inputDraftListA2 = [];
                        }
                    }).call()

                    Api(context, draftList.getMcrItemMpn(data.mcr_item_code)).onSuccess(function(response) {
                        for (var i=0; i<response.data.data.length; i++) {
                            context.manufacturePartNumberA2.manufacture_code[i] = response.data.data[i].manufacture_code;
                            context.manufacturePartNumberA2.mpn[i] = response.data.data[i].mpn;
                            context.manufacturePartNumberA2.manufacture_type[i] = response.data.data[i].manufacture_type;
                            context.manufacturePartNumberA2.manufacture_note[i] = response.data.data[i].manufacture_note;
                        }                        
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.manufacturePartNumberA2.manufacture_code = [];
                            context.manufacturePartNumberA2.mpn = [];
                            context.manufacturePartNumberA2.manufacture_type = [];
                            context.manufacturePartNumberA2.manufacture_note = [];
                        }
                    }).call()

                    Api(context, draftList.getMcrItemEquipment(data.mcr_item_code)).onSuccess(function(response) {
                        for (var i=0; i<response.data.data.length; i++) {
                            context.equipmentQuantityInstallA2.plant[i] = response.data.data[i].plant_code;
                            context.equipmentQuantityInstallA2.equipment_code[i] = response.data.data[i].equipment_code;
                            context.equipmentQuantityInstallA2.qty_installed[i] = response.data.data[i].qty_installed;
                            context.equipmentQuantityInstallA2.drawing_number[i] = response.data.data[i].drawing_number;
                        }                        
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.equipmentQuantityInstallA2.plant = [];
                            context.equipmentQuantityInstallA2.equipment_code = [];
                            context.equipmentQuantityInstallA2.qty_installed = [];
                            context.equipmentQuantityInstallA2.drawing_number = [];
                        }
                    }).call()
                } 
                
            },
            remove(data) {
                let context = this;
                context.confirmDialog(context.tt('confirm_delete')).then((result) => {
                    if (result.value) {
                        Api(context, draftList.deleteMcrItem(data.id)).onSuccess(function(response) {
                            context.$notify({
                                message: response.data.message,
                                type: 'success'
                            });
                            context.get();
                        }).call();
                    }
                })
            },
            create(key) {
                    this.inputDraftListA2.material_number = '';
                    this.inputDraftListA2.client_plant_level = 'client';
                    this.inputDraftListA2.item_type_code = '';
                    this.inputDraftListA2.critical_non_critical = '';
                    this.inputDraftListA2.bom_non_bom = '';
                    this.inputDraftListA2.fab_non_fab = '';
                    this.inputDraftListA2.item_name = '';
                    this.inputDraftListA2.specification = '';
                    this.manufacturePartNumberA2.manufacture_code = [];
                    this.manufacturePartNumberA2.mpn = [];
                    this.manufacturePartNumberA2.manufacture_type = [];
                    this.manufacturePartNumberA2.manufacture_note = [];
                    this.equipmentQuantityInstallA2.plant = [];
                    this.equipmentQuantityInstallA2.equipment_code = [];
                    this.equipmentQuantityInstallA2.qty_installed = [];
                    this.equipmentQuantityInstallA2.drawing_number = [];
                    this.inputDraftListA2.plant_code = '';
                    this.inputDraftListA2.abc_indicator = '';
                    this.inputDraftListA2.min_stock = '';
                    this.inputDraftListA2.mrp_types = '';
                    this.inputDraftListA2.max_stock = '';
                    this.inputDraftListA2.reason = '';
                    this.inputDraftListA2.note = '';

                    this.form.a2.show = true;
                    this.form.a2.disabled = false;
                    this.form.a2.type = 'add';

                    this.getMaterialNumber();
            },
            editMcrInformation() {
                this.formMcrInformation.add = false;
                this.formMcrInformation.show = true;
            }, 
            updateMcrInformation() {
                let context = this;     
                // context.errorMessage = null;      
                context.btnUpdate.onLoading = true;    
                Api(context, draftList.updateMcrSubject(context.mcrInformation.mcr_code, context.mcrInformation.token, context.mcrInformationEdit)).onSuccess(function(response) {
                    context.mcrInformation.subject = context.mcrInformationEdit.new_subject;
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success'
                    });
                }).onFinish(function() {
                    context.btnUpdate.onLoading = false;
                    context.formMcrInformation.show = false;
                }).call()        
            },
            // changeFileAttachmentNameA1(files) {
            //     this.attachmentDocumentA1.file_attachment_name[this.attachmentDocumentA1.file_attachment_name.length-1] = files[0]                
            // },
            
            changeMaterialNumberA2() {         
                let context = this;
                Api(context, endUserSearchMaterial.partCharValueEndUser({material_number: context.inputDraftListA2.material_number})).onSuccess(function(response) {    
                    // context.endUserSearchMaterial = response.data.data.data.data;                    
                    context.inputDraftListA2.item_type_code = response.data.data.part_master[0].item_type_code;
                    context.inputDraftListA2.critical_non_critical = response.data.data.part_master[0].critical_non_critical;
                    context.inputDraftListA2.bom_non_bom = response.data.data.part_master[0].bom_non_bom;
                    context.inputDraftListA2.fab_non_fab = response.data.data.part_master[0].fab_non_fab;
                    context.inputDraftListA2.item_name = response.data.data.part_master[0].item_name;

                    for (var i=0; i<response.data.data.part_manufacture_ref.length; i++) {
                        context.manufacturePartNumberA2.manufacture_code[i] = response.data.data.part_manufacture_ref[i].mancode;
                        context.manufacturePartNumberA2.mpn[i] = response.data.data.part_manufacture_ref[i].manufacture_ref;
                        context.manufacturePartNumberA2.manufacture_type[i] = response.data.data.part_manufacture_ref[i].type;
                        context.manufacturePartNumberA2.manufacture_note[i] = response.data.data.part_manufacture_ref[i].note;
                    }

                    for (var i=0; i<response.data.data.part_equipment_code.length; i++) {
                        context.equipmentQuantityInstallA2.plant[i] = response.data.data.part_equipment_code[i].plant_code;
                        context.equipmentQuantityInstallA2.equipment_code[i] = response.data.data.part_equipment_code[i].equipment_code;
                        context.equipmentQuantityInstallA2.qty_installed[i] = response.data.data.part_equipment_code[i].qty_installed;
                        context.equipmentQuantityInstallA2.drawing_number[i] = response.data.data.part_equipment_code[i].drawing_number;
                    }

                    context.plantLevel = response.data.data.part_plant;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.manufacturePartNumberA2.manufacture_code = [];
                        context.manufacturePartNumberA2.mpn = [];
                        context.manufacturePartNumberA2.manufacture_type = [];
                        context.manufacturePartNumberA2.manufacture_note = [];

                        context.equipmentQuantityInstallA2.plant = [];
                        context.equipmentQuantityInstallA2.equipment_code = [];
                        context.equipmentQuantityInstallA2.qty_installed = [];
                        context.equipmentQuantityInstallA2.drawing_number = [];
                    }
                })
                .call()
            },
            getMaterialPlant() {
                let context = this;               
                Api(context, endUserSearchMaterial.getMaterialPlant({material_number: context.inputDraftListA2.material_number, plant_code: context.inputDraftListA2.plant_code})).onSuccess(function(response) {    
                    context.inputDraftListA2.abc_indicator_code = response.data.data.part_mrp[0].abc_indicator_code;
                    context.inputDraftListA2.mrp_type_code = response.data.data.part_mrp[0].mrp_type_code;
                    context.inputDraftListA2.max_stock = response.data.data.part_mrp[0].max;
                    context.inputDraftListA2.min_stock = response.data.data.part_mrp[0].min;
                    console.log(response.data.data);
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.plantCode = [];
                    }
                })
                .call()
            },
            changeClientPlantLevelA2() {
                // if (this.inputDraftListA2.client_plant_level == 'client') {
                //     this.condition.a2.client_level.show = true;
                // } else {
                //     this.condition.a2.client_level.show = false;
                // }
            },
            saveA2(save) {
                let context = this;    
                let formData = new FormData();

                if (context.inputDraftListA2.material_number != '' &&
                    context.inputDraftListA2.client_plant_level != '' &&
                    context.inputDraftListA2.reason != '') {        
                    let data = {
                        mcr_code: context.mcr_code,
                        material_number: context.inputDraftListA2.material_number,
                        client_plant_level: context.inputDraftListA2.client_plant_level,

                        reason: context.inputDraftListA2.reason,
                        note: context.inputDraftListA2.note,
                    }

                    if (context.inputDraftListA2.client_plant_level == 'client') {
                        if (context.inputDraftListA2.critical_non_critical != '') {
                            data.critical_non_critical = context.inputDraftListA2.critical_non_critical;
                        } else {
                            context.$notify({
                                message: 'Please complete data!',                  
                                type: 'danger'
                            });
                            return false;
                        }
                        data.item_type_code = context.inputDraftListA2.item_type_code;
                        data.bom_non_bom = context.inputDraftListA2.bom_non_bom;
                        data.fab_non_fab = context.inputDraftListA2.fab_non_fab;
                        data.item_name = context.inputDraftListA2.item_name;
                        data.specification = context.inputDraftListA2.specification;
                        

                        for (var i=0; i<context.manufacturePartNumberA2.manufacture_code.length; i++) {
                            if (context.manufacturePartNumberA2.manufacture_code[i] != '' &&
                                context.manufacturePartNumberA2.mpn[i] != '' &&
                                context.manufacturePartNumberA2.manufacture_type[i] != '' &&
                                context.manufacturePartNumberA2.manufacture_note[i] != '') {
                                

                                data.manufacture_code = context.manufacturePartNumberA2.manufacture_code;
                                data.mpn = context.manufacturePartNumberA2.mpn;
                                data.manufacture_type = context.manufacturePartNumberA2.manufacture_type;
                                data.manufacture_note = context.manufacturePartNumberA2.manufacture_note;

                            } else {
                                context.$notify({
                                    message: 'Please complete data!',                  
                                    type: 'danger'
                                });
                                return false;
                            }
                        }
                        
                        for (var i=0; i<context.equipmentQuantityInstallA2.plant.length; i++) {
                            if (context.equipmentQuantityInstallA2.plant[i] != '' &&
                                context.equipmentQuantityInstallA2.equipment_code[i] != '' &&
                                context.equipmentQuantityInstallA2.qty_installed[i] != '' &&
                                context.equipmentQuantityInstallA2.drawing_number[i] != '') {
                                
                                data.plant_equipment_code = context.equipmentQuantityInstallA2.plant;
                                data.equipment_code = context.equipmentQuantityInstallA2.equipment_code;
                                data.qty_installed = context.equipmentQuantityInstallA2.qty_installed;
                                data.drawing_number = context.equipmentQuantityInstallA2.drawing_number;

                                // formData.append('plant['+i+']', context.equipmentQuantityInstallA2.plant[i])
                                // formData.append('equipment_code['+i+']', context.equipmentQuantityInstallA2.equipment_code[i])
                                // formData.append('qty_installed['+i+']', context.equipmentQuantityInstallA2.qty_installed[i])
                                // formData.append('drawing_number['+i+']', context.equipmentQuantityInstallA2.drawing_number[i])
                            } else {
                                context.$notify({
                                    message: 'Please complete data!',                  
                                    type: 'danger'
                                });
                                return false;
                            }
                        }

                        // console.log(data)
                        // return false
                    } else {
                        if (context.inputDraftListA2.min_stock != '' &&
                            context.inputDraftListA2.max_stock != '') {
                                // return false
                                data.plant_code = context.inputDraftListA2.plant_code;
                                data.abc_indicator_code = context.inputDraftListA2.abc_indicator_code;
                                data.mrp_type_code = context.inputDraftListA2.mrp_type_code;
                                data.min_stock = context.inputDraftListA2.min_stock;
                                data.max_stock = context.inputDraftListA2.max_stock;

                                // formData.append('abc_indicator_code', context.inputDraftListA2.abc_indicator_code)
                                // formData.append('min_stock', context.inputDraftListA2.min_stock)
                                // formData.append('mrp_type_code', context.inputDraftListA2.mrp_type_code)
                                // formData.append('max_stock', context.inputDraftListA2.max_stock)
                        } else {
                            context.$notify({
                                message: 'Please complete data!',                  
                                type: 'danger'
                            });
                            return false
                        }
                    }

                    // formData.append('reason', context.inputDraftListA2.reason)
                    // formData.append('note', context.inputDraftListA2.note)

                    let api = '';
                    if (save == 'add') {
                        api = Api(context, draftList.createNewMcrItemNoFormData(data));
                    } else {
                        api = Api(context, draftList.updateMcrItemNoFormData(data, context.inputDraftListA2.id));
                    }
                    api.onSuccess(function(response) {    
                        // context.draftList = response.data; //.data.data.data;
                        // this.form.show = false;
                        context.get();
                        context.$notify({
                            message: response.data.message,                  
                            type: 'success'
                        });
                        context.form.a2.show = false;
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.draftList = [];
                        }
                    }).call()
                } else {
                    context.$notify({
                        message: 'Please complete data!',                  
                        type: 'danger'
                    });
                }
            },
            sendMcr(mcr_code) {
              this.btnSend.onLoading = true;
              let api = "";
              let context = this;
              api = Api(context, draftList.sendMcr(mcr_code));
                api.onSuccess(function(response) {
                  context.$notify({
                    message: response.data.message,
                    type: "success"
                  });
                  context.$router.push('/material/draft-list');
                })
                .onFinish(function() {
                  context.btnSend.onLoading = false;
                })
                .call();
            },
            toggleAccordion(show, type) {
                if (type == 'clA2') {
                    if (show == '') {
                        this.accordion.a2.cl.show = 'show'
                    } else {
                        this.accordion.a2.cl.show = ''
                    }
                } else if (type == 'plA2') {
                    if (show == '') {
                        this.accordion.a2.pl.show = 'show'
                    } else {
                        this.accordion.a2.pl.show = ''
                    }
                } else if (type == 'mpnA2') {
                    if (show == '') {
                        this.accordion.a2.mpn.show = 'show'
                    } else {
                        this.accordion.a2.mpn.show = ''
                    }
                } else if (type == 'eqiA2') {
                    if (show == '') {
                        this.accordion.a2.eqi.show = 'show'
                    } else {
                        this.accordion.a2.eqi.show = ''
                    }
                }
            },

            // AUTOCOMPLETE

            querySearchMatNum(queryString, cb) {
                if (queryString.length > 2) {
                    var links = this.materialNumber;
                    var results = queryString ? links.filter(this.createFilter(queryString)) : links;
                    cb(results);
                }
            },
            querySearchManufacture(queryString, cb) {
              if (queryString.length > 2) {
                var links = this.manufacture;
                var results = queryString ? links.filter(this.createFilter(queryString)) : links;
                cb(results);
              }
            },
            selectManufacture(item) {
                this.manufacturePartNumberA2.manufacture_code[this.manufactureKey] = item.code
            },
            selectedManufactureKey(key) {
              this.manufactureKey = key;
            },
            querySearchEquipment(queryString, cb) {
              if (queryString.length > 2) {
                var links = this.equipmentCode;
                var results = queryString ? links.filter(this.createFilter(queryString)) : links;
                cb(results);
              }
            },
            selectEquipment(item) {
                this.equipmentQuantityInstallA2.equipment_code[this.equipmentKey] = item.code
            },
            selectedEquipmentKey(key) {
              this.equipmentKey = key;
            },
            querySearchPlant(queryString, cb) {
              var links = this.plantCode;
              var results = queryString ? links.filter(this.createFilter(queryString)) : links;
              cb(results);
            },
            selectPlant(item) {
                this.inputDraftListA2.plant_code[this.plantKey] = item.code
            },
            selectedPlantKey(key) {
              this.plantKey = key;
            },
            createFilter(queryString) {
                return (link) => {
                return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) > -1);
                };
            },
            tracking() {
                this.formTracking.show = false;
                this.formTracking.show = true;
            },
        }   
    };
</script>
<style></style>
